@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');


html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  color: #212121;
  font-family: 'Inter', sans-serif;
  height: 100%;
  scroll-behavior: smooth;
  /* overflow: hidden; */
  /* background-color: #FCFAF4; */
}

main {
  /* padding-top: 90px; */
  background: linear-gradient(171deg, #FEF9F1 0%, #FFF 20.83%, #FEFCF7 50%, #FFF 73.44%, #FEF9F1 100%);
}

@media screen and (max-width: 1100px) {
  main {
    /* padding-top: 72px; */
    background: linear-gradient(171deg, #FEF9F1 0%, #FFF 20.83%, #FEFCF7 50%, #FFF 73.44%, #FEF9F1 100%);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --blue-navy: #0C2661;
  --blue-text: #0C2661;
  --black-text: #212121;
  --gray: #EAEBEF;
  --white: #ffffff;
  --black: #000000;
  --green-1: #70F1BF;
  --blue-1: #0083FD;

  --background: #F5F6F9;
  --gray-1: #212121;
  --gray-3: #586174
}

#mobile {
  display: none;
}

.pointer {
  cursor: pointer;
}


.react-tel-input .form-control {
  border-radius: 6px;
  border: 1px solid var(--border-border-500, #D0D5DD);
  background: var(--base-white, #FFF);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;
  color: var(--text-text-500, #1D2939);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100% !important;
  resize: unset;
  font-family: 'Inter', sans-serif !important; 
  min-height: 46.5px;
  justify-content: space-between; 
}

